import { useMemo } from 'react';

interface FormattedPhoneProps {
    phone: string;
};

export const FormattedPhone: React.FC<FormattedPhoneProps> = ({ phone }) => {

    const formatted = useMemo<string>(() => `${phone.slice(0, phone.length - 7)}-${phone.slice(phone.length - 7, phone.length - 4)}-${phone.slice(phone.length - 4)}`, [phone]);

    return <>{formatted}</>;
};